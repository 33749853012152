import React, { Fragment } from "react"
import { connectSearchBox, connectHits } from "react-instantsearch-dom"
import "./search.css"

const config = require("../../../data/SiteConfig");

const _ = require("lodash");

const SearchBox = ({ currentRefinement, refine }) => (
  <Fragment>
    <form noValidate role="search">
      <input
        value={currentRefinement}
        onChange={event => refine(event.currentTarget.value)}
        
      />
    </form>
  </Fragment>
)

export const CustomSearchBox = connectSearchBox(SearchBox)

// on page load do not display
const Hits = ({ hits }) => (
  // if parent component set is type, render, otherwise hide
  <ul className="style pt-1 pb-1">
    {hits.length < 1 ? <li>No search results found</li> : ""}
    {hits.map(hit => (
      <li key={hit.id} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">
        <a href={config.siteUrl + '/markets/' + _.kebabCase(hit.data.exchangeName) + '/' + _.kebabCase(hit.data.name) + '/'} className="block w-full">
        <span
          className="text-sm"
          dangerouslySetInnerHTML={{ __html: hit.data.name }}
        />
        <p
          className="text-xs"
          dangerouslySetInnerHTML={{ __html: hit.data.symbol }}
        />
        <p
          className="text-xs"
          dangerouslySetInnerHTML={{ __html: hit.data.exchangeName }}
        />
        </a>
      </li>
    ))}
  </ul>
)

export const CustomHits = connectHits(Hits)
