import React from "react"
const config = require("../../../data/SiteConfig")

const SiteNavLogo = () => {

  return (
    <div className="w-auto flex pt-0.5">

    <a
      href="/"
      className="flex h-8 w-8 text-teal-100"
    >
    <svg className="h-8 w-8 stroke-current" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"><path d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"></path></svg>    

    </a>
    
    <a
      className="flex pl-2 w-auto text-2xl font-bold text-white hidden lg:inline-block"
      href="/"
    >
      {config.siteTitle}
          
    </a>
    </div>    
  )
}

export default SiteNavLogo