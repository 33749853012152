import React, { Component } from "react";
const config = require("../../../data/SiteConfig")

class FourColumnWithNewsletterDark extends Component {
  render() {
    return (

        <div className={config.footerBackgroundDark}>
        <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
            <div className="xl:grid xl:grid-cols-1 xl:gap-8">
            <div className="grid grid-cols-2 gap-8 xl:col-span-2">
                <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                    <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-500 uppercase">
                    {config.firstFooterMenuTitle}
                    </h4>
                    <ul className="mt-4">

                    {config.firstFooterMenu.map((item) => (
                    <li className="mt-4">
                        <a href={item.path} className="text-base leading-6 text-gray-300 hover:text-white">
                        {item.label}
                        </a>
                    </li>                  
                    ))}                  

                    </ul>
                </div>
                <div className="mt-12 md:mt-0">
                    <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-500 uppercase">
                    {config.secondFooterMenuTitle}
                    </h4>
                    <ul className="mt-4">
                    {config.secondFooterMenu.map((item) => (
                    <li className="mt-4">
                        <a href={item.path} className="text-base leading-6 text-gray-300 hover:text-white">
                        {item.label}
                        </a>
                    </li>                  
                    ))}                     

                    </ul>
                </div>
                </div>
                <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                    <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-500 uppercase">
                    {config.thirdFooterMenuTitle}
                    </h4>
                    <ul className="mt-4">
                    {config.thirdFooterMenu.map((item) => (
                    <li className="mt-4">
                        <a href={item.path} className="text-base leading-6 text-gray-300 hover:text-white">
                        {item.label}
                        </a>
                    </li>                  
                    ))}                     

                    </ul>
                </div>
                <div className="mt-12 md:mt-0">
                    <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-500 uppercase">
                    {config.fourthFooterMenuTitle}
                    </h4>
                    <ul className="mt-4">
                    {config.fourthFooterMenu.map((item) => (
                    <li className="mt-4">
                        <a href={item.path} className="text-base leading-6 text-gray-300 hover:text-white">
                        {item.label}
                        </a>
                    </li>                  
                    ))}                     

                    </ul>
                </div>
                </div>
            </div>
            </div>
            <div className="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">

            <p className="mt-8 text-base leading-6 text-gray-500 md:mt-0 md:order-1">
                &copy; {(new Date().getFullYear())} {config.siteTitle}
            </p>
            </div>
            <p className="mt-8 pt-8 text-base leading-6 text-gray-500 md:mt-0 md:order-1">
                While Shareprice.ie does what we can to maintain the high quality of the information displayed on this site, we cannot be held responsible for any loss due to incorrect information found here. All information is provided free of charge, 'as-is', and you use it at your own risk. 
            </p>            
        </div>
        </div>




    );
  }
}

export default FourColumnWithNewsletterDark;
