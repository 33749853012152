import React, { Fragment } from "react"

const config = require("../../../data/SiteConfig");


//Algolia Search Start
import { CustomHits } from "./searchPreview"
import algoliasearch from "algoliasearch/lite"
import {
  InstantSearch,
  SearchBox,
  Hits,
  Configure,
} from "react-instantsearch-dom"

const ClickOutHandler = require("react-onclickout")

const algoliaClient = algoliasearch(
  "MYW2145OJY",
  "8ba2a404a3d4ffc60bacfd2ed8b69f88"
)

// removes empty query searches from analytics
const searchClient = {
  search(requests) {
    const newRequests = requests.map(request => {
      // test for empty string and change request parameter: analytics
      if (!request.params.query || request.params.query.length === 0) {
        request.params.analytics = false
      }
      return request
    })
    return algoliaClient.search(newRequests)
  },
}
// Algolia Search End

class Index extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      hasInput: false,
      refresh: false,
    }
  }



  render() {
    const { refresh, hasInput } = this.state

    return (
      <div className="flex-1 flex items-center justify-center px-2 lg:ml-6 lg:justify-end relative">
        <div className="max-w-lg w-full lg:max-w-xs">

          <label for="search" className="sr-only">Search</label>
          {/* Aloglia Widgets Start */}
          <div className="relative">
              <InstantSearch
                searchClient={searchClient}
                indexName={`prices`}
                refresh={refresh}
              >
                <Configure hitsPerPage={5} />

                {/* forcefeed className because component does not accep natively as prop */}
                <SearchBox
                  className="block w-full pl-1 pr-1 py-0 border border-gray-300 rounded-l-md leading-5 bg-white placeholder-gray-500 sm:text-sm "                
                  submit={<Fragment />}
                  id="search-input"
                  reset={<Fragment />}
                  translations={{
                    placeholder: config.mainMenuSearchText,
                  }}
                  onKeyUp={event => {
                    this.setState({
                      hasInput: event.currentTarget.value !== "",
                    })
                  }}           
                />

                <div className={!hasInput ? "hidden" : "z-10 origin-top-left absolute left-0 mt-2 w-80 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"}>
                  <CustomHits hitComponent={Hits} />
                </div>
              </InstantSearch>
          </div>
          {/* Aloglia Widgets End */}
        </div>
            <button aria-label="Search" class="-ml-px relative inline-flex items-center px-4 py-2 border border-transparent leading-5 font-medium rounded-r-md text-base font-medium text-white bg-teal-400 hover:bg-teal-300 focus:outline-none focus:bg-indigo-400 transition ease-in-out duration-150"> 
              <svg className="h-5 w-5 text-white" fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
              </svg>
            </button>            
      </div>
    )
  }
}

export default Index
