const config = {
  siteTitle: "shareprice.ie", // Site title.
  siteTitleShort: "shareprice.ie", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: "shareprice.ie", // Alternative site title for SEO.
  siteLogo: "/logos/logo-1024.png", // Logo used for SEO and manifest.
  siteUrl: "https://www.shareprice.ie", // Domain of your website without pathPrefix.
  siteTagLine: "Master The Markets. Up to date share prices and market data to help you make better trading decisions.",
  siteFBAppID: "387570352269554",
  pathPrefix: "/", // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  siteDescription: "shareprice.ie", // Website description used for RSS feeds/meta description tag.
  dateFromFormat: "YYYY-MM-DD", // Date format used in the frontmatter.
  dateFormat: "DD/MM/YYYY", // Date format for display.
  postsPerPage: 2000, // Amount of posts displayed per listing page.
  socialFacebook: "https://www.facebook.com/", // Optionally renders "Follow Me" in the UserInfo segment.
  socialInstagram: "https://www.instagram.com/", // Optionally renders "Follow Me" in the UserInfo segment.  
  socialTwitter: "https://www.twitter.com/", // Optionally renders "Follow Me" in the UserInfo segment.
  themeColor: "#EDF2F7", // Used for setting manifest and progress theme colors.
  backgroundColor: "#e0e0e0", // Used for setting manifest background color.
  headerBackgroundLight:"bg-white shadow",
  footerBackgroundLight: "bg-white",
  headerBackgroundDark:"bg-gray-900",
  footerBackgroundDark: "bg-gray-900",
  fontFamily: "Nunito",
  blogDirectory: "markets",
  emailSubscribeButtonText: "Subscribe",
  emailSubscribeTitleText: "Free market and business news",
  emailSubscribeDescriptionText: "The latest business and market news to your inbox",
  emailSubscribePrivacyText: "We care about the protection of your data. Read our",
  emailSubscribePrivacyCTA: "Privacy Policy",
  contactTitle: "Get in touch",
  contactSubTitle: "We'd love to hear from you",
  contactSubmit: "Let's talk!",
  sendySubscribeUrl: "https://email.shareprice.ie/sendy/subscribe",
  sendyListID: "DEiFMSeqP7633fhQbbBa6qow",
  formSpreeUrl: "https://formspree.io/f/xvovkerr",
  


// Top Menu

  mainMenu: [
    {
      label: 'Irish Share Prices',
      path: '/markets/euronext-dublin/'
    },
    {
      label: 'FTSE Share Prices',
      path: '/markets/london-stock-exchange/'
    },    
  ],  
  mainMenuCTAText: "Start",
  mainMenuCTAPath: "/",
  mainMenuSearchText: "Share price search",
  mainMenuSearchButtonText: "Search",

// Footer menus  
  firstFooterMenuTitle: "Markets",
  firstFooterMenu: [
    {
      label: 'Irish Share Prices',
      path: '/markets/euronext-dublin/'
    },
    {
      label: 'FTSE Share Prices',
      path: '/markets/london-stock-exchange/'
    },
    {
      label: 'Crypto',
      path: '/crypto/'
    }
  ],  

  secondFooterMenuTitle: "Market News",  
  secondFooterMenu: [
    {
      label: 'Irish Business News',
      path: '/news/irish/'
    },
    {
      label: 'UK Business News',
      path: '/news/uk/'
    },
    {
      label: 'Bitcoin News',
      path: '/news/bitcoin/'
    }
  ],   

  thirdFooterMenuTitle: "Company",  
  thirdFooterMenu: [
    {
      label: 'About',
      path: '/about/'
    },
    {
      label: 'Contact Us',
      path: '/contact/'
    }
  ],    

  fourthFooterMenuTitle: "Legal",
  fourthFooterMenu: [
    {
      label: 'Terms and Conditions',
      path: '/terms/'
    },
    {
      label: 'Privacy Policy',
      path: '/privacy/'
    },
    {
      label: 'Cookie Policy',
      path: '/cookies/'
    }
  ],    
};

// Validate

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === "/") {
  config.pathPrefix = "";
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, "")}`;
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === "/")
  config.siteUrl = config.siteUrl.slice(0, -1);


module.exports = config;
