import React, { Component } from "react";
import SiteNavLogo from "../../components/Logos/SiteNavLogo";
import { Link } from "gatsby";
import SearchBasic from "../../components/Search/SearchBasic";
const config = require("../../../data/SiteConfig");

class DarkWithSearch extends Component {

  state = {
     isActive:false
  }

  handleShow = ()=>{
      this.setState({
          isActive: true
      })
  }

  handleHide = () =>{
      this.setState({
          isActive: false
      })
  }    

  render() {
    return (
        <nav className={config.headerBackgroundDark}>
        <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
            <div className="flex items-center px-2 lg:px-0">
                <div className="flex-shrink-0">
                <SiteNavLogo />
                </div>
                <div className="hidden lg:block lg:ml-6 pt-0.5">
                <div className="flex">
                    {config.mainMenu.map((item) => (
                        <Link 
                            to={item.path} 
                            className={'ml-4 px-3 py-2 rounded-md text-base leading-5 font-medium text-teal-50 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out'}
                            activeClassName={'px-3 py-2 rounded-md text-base leading-5 font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out'}
                            >
                            {item.label}
                        </Link>          
                    ))}                
                </div>
                </div>
            </div>
            <div className="flex-1 px-2 lg:ml-6 lg:justify-end pt-0 block z-50">
                <SearchBasic />
            </div> 
            <div className="flex lg:hidden">
                {this.state.isActive ?

                <button onClick={this.handleHide} className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white transition duration-150 ease-in-out">
                    <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"/>
                    </svg>
                </button>

                : 
                <button onClick={this.handleShow} className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white transition duration-150 ease-in-out">
                    <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"/>
                    </svg>
                </button>
                }
            </div>
            </div>
        </div>

        {this.state.isActive ?
            <div className="px-2 pt-2 pb-3">
                    {config.mainMenu.map((item) => (
                        <a href={item.path} className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-teal-50 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out">{item.label}</a>
                    ))}          
            </div>
        : 
        null
        }

        </nav>

    );
  }
}

export default DarkWithSearch;