import React, { Component } from "react";
import config from "../../../data/SiteConfig";
import FourColumnWithNewsletterDark from "../../components/Footers/FourColumnWithNewsletterDark";

class Footer extends Component {
  render() {
    const { config } = this.props;
    return (
      <FourColumnWithNewsletterDark />
    );
  }
}

export default Footer;
