import React, { Component } from "react";
import DarkWithSearch from "../../components/Navbars/DarkWithSearch";

class Header extends Component {
  render() {
    const { config } = this.props;
    return (
        <DarkWithSearch />
    );
  }
}

export default Header;
